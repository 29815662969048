const createFile = (data, filename, mime, bom) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, {
    type: mime || 'application/octet-stream'
  });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const linkFile = document.createElement('a');
    // linkFile.style.display = 'none';
    linkFile.href = blobURL;
    linkFile.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof linkFile.download === 'undefined') {
      linkFile.setAttribute('target', '_blank');
    }

    document.body.appendChild(linkFile);
    linkFile.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function() {
      document.body.removeChild(linkFile);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};
const getFileName = response => {
  const headerLine = response.headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  let filename = null;

  const matches = filenameRegex.exec(headerLine);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
    return filename;
  }
  throw new Error('File excel không đúng!');
};

module.exports = {
  createFile,
  getFileName
};
