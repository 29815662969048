<template>
  <div>
    <KTCodePreview v-bind:title="'Báo cáo tổng hợp'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="3">
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <b-form-select
                size="sm"
                v-model="selectedDepartmentId"
                :options="listDepartment"
                value-field="id"
                text-field="name"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- Chọn bộ phận --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="1" v-if="checkPermission('RR_VIEW_ALL')">
            <b-button variant="primary" size="sm" @click="fetchData()"
              >Lọc</b-button
            >
          </b-col>
          <b-col md="6">
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-4"
              @click="reportClick(1)"
              v-if="checkPermission('RR_VIEW_ALL')"
              >Xuất báo cáo</b-button
            >
            <b-button
              variant="success"
              size="sm"
              class="mr-4"
              @click="reportClick(2)"
              v-if="checkPermission('RR_VIEW_ALL')"
              >Xuất báo cáo chi tiết</b-button
            >
            <b-button
              variant="warning"
              size="sm"
              @click="reportClick(3)"
              v-if="
                checkPermission('RR_VIEW_ALL') ||
                checkPermission('RR_VIEW_BILL')
              "
              >Danh sách hóa đơn</b-button
            >
          </b-col>
        </b-row>

        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          hover
          bordered
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(totalPoint)="row">
            <div style="text-align: right">
              <span
                v-text="formatMoney(row.item.totalPoint)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(addPoint)="row">
            <div style="text-align: right">
              <div
                class="productCode text-primary"
                @click="linkToDetail(row.item)"
                style="cursor: pointer"
              >
                <span
                  v-text="formatMoney(row.item.addPoint)"
                  class="text-right"
                ></span>
              </div>
            </div>
          </template>
          <template v-slot:cell(subtractPoint)="row">
            <div style="text-align: right">
              <div
                class="productCode text-warning"
                @click="linkToDetail(row.item)"
                style="cursor: pointer"
              >
                <span
                  v-text="formatMoney(row.item.subtractPoint)"
                  class="text-right"
                ></span>
              </div>
            </div>
          </template>
          <template v-slot:cell(finalPoint)="row">
            <div style="text-align: right">
              <span
                v-text="formatMoney(row.item.finalPoint)"
                class="text-right"
              ></span>
            </div>
          </template>
        </b-table>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { BASE_URL } from '@/utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
// import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import axios from 'axios';
import fileDownload from '@/utils/file-download';
import localData from '@/utils/saveDataToLocal';
import { makeToastFaile } from '@/utils/common';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      searchFromDay: '',
      searchToDay: '',
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã nhân viên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'fullName',
          label: 'Tên nhân viên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'jobTitleName',
          label: 'Chức vụ',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'departmentName',
          label: 'Bộ phận',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'totalPoint',
          label: 'Điểm thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'addPoint',
          label: 'Cộng khác',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'subtractPoint',
          label: 'Trừ khác',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'finalPoint',
          label: 'Tổng thưởng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
      ],
      listItem: [],
      listDepartment: [],
      selectedDepartmentId: null,
    };
  },
  methods: {
    reportClick: async function (mode) {
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';

      if (!searchFromDay) {
        makeToastFaile('Bạn chưa chọn ngày bắt đầu!');
        return;
      }
      if (!searchToDay) {
        makeToastFaile('Bạn chưa chọn ngày kết thúc!');
        return;
      }

      let route = '';
      switch (mode) {
        case 1:
          route = 'export-report';
          break;
        case 2:
          route = 'export-excel-detail';
          break;
        case 3:
          route = 'export-list-bill';
          break;
      }

      const url = `${BASE_URL}reward-cr-reports/${route}?fromDate=${searchFromDay}&toDate=${searchToDay}&departmentId=${this.selectedDepartmentId}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    fetchData: function () {
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      if (!this.selectedDepartmentId) {
        return makeToastFaile('Vui lòng chọn bộ phận xem báo cáo!')
      }
      const params = {
        fromDate: searchFromDay,
        toDate: searchToDay,
        departmentId: this.selectedDepartmentId,
      };
      this.onLoading = true;
      ApiService.query(`reward-cr-reports`, { params }).then(({ data }) => {
        this.listItem = [];
        if (data.status === 1) {
          this.listItem = data.data.map((item, index) => {
            return {
              count: parseInt(index) + 1,
              ...item,
            };
          });
        } else {
          makeToastFaile(data.message);
        }
        this.onLoading = false;
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    linkToDetail: function (item) {
      const searchFromDay = this.dpForm.startDate;
      const searchToDay = this.dpForm.endDate;
      const { href } = this.$router.resolve({
        name: 'reward-detail',
        query: {
          fromDate: searchFromDay,
          toDate: searchToDay,
          employeeId: item.id,
          employeeName: item.fullName,
        },
      });
      window.open(href, '_blank');
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    fetchDeparment: async function () {
      this.listDeparment = [];
      ApiService.get('/employees/departments').then((response) => {
        this.listDepartment = response.data.data;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Báo cáo hoa hồng tổng hợp', route: 'overview' },
      { title: 'Danh sách báo cáo hoa hồng tổng hợp' },
    ]);
    this.fetchDeparment();
  },
  created() {},
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
